import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import LoadRenewalWrapper from 'components/ApiRequestWrapper/LoadRenewalWrapper';
import FormRedirectionWrapper from 'components/FormRedirectionWrapper';
import Layout from 'components/Layout';
import AboutYourCoverForm from 'forms/AboutYourCoverForm';
import { trackCheckoutEvent } from 'helpers/ecommerceTracking';
import { renewalRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { useUser } from 'state/user/state';
import { CsHero, CsMeta } from 'types/contentStack';

type AboutYourCoverProps = {
  data: {
    csAboutYourCover: {
      meta: CsMeta;
      hero: CsHero;
      next_button_text: string;
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csAboutYourCover {
      meta {
        meta_title
      }
      hero {
        heading
        subheading
      }
      next_button_text
    }
  }
`;

const AboutYourCover: React.FC<AboutYourCoverProps & PolicyNumberProps> = ({
  data: {
    csAboutYourCover: {
      meta,
      hero: { heading, subheading },
      next_button_text,
    },
  },
  location,
  policyNumber,
}) => {
  const [user] = useUser();
  usePageTracking(meta.meta_title, !!user?.isLoggedIn);

  useEffect(() => trackCheckoutEvent(3, null, 'Renewals'), []);

  return (
    <Layout meta={meta} pageType="mtaAndRenewal">
      <LoadRenewalWrapper policyNumber={policyNumber}>
        <FormRedirectionWrapper location={location} isRenewal>
          <HeroPlain heading={heading} subhead={subheading || undefined} />
          <AboutYourCoverForm
            moveBack={() => navigate(renewalRoutes.aboutYou(policyNumber))}
            moveNextText={next_button_text}
            moveNext={() => navigate(renewalRoutes.additionalDrivers(policyNumber))}
            isRenewal
          />
        </FormRedirectionWrapper>
      </LoadRenewalWrapper>
    </Layout>
  );
};

export default withPolicyNumber(AboutYourCover);
